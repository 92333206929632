import React from "react";
import "../styles/page4.scss";
// import OurTrainer from "./OurTrainer";
import img from "../assets/page4/yashpng.png";
// import OurExpertUpdated from "../components/ourExpertTrainerUpdated.jsx";
import OurExpertTrainer from "../components/ourExpertTrainerUpdated.jsx";
export default function Page4() {
  const items = [
    {
      name: "Dhanajay Shetty",
      image: img,
      Experience: 10,
      Certification: 5,
      xyz: "xyz",
      content: "Mentor",
    },
    {
      name: "Mr. Yash Gupta",
      image: img,
      Experience: 8,
      Certification: 7,
      xyz: "xyz",
      content: "Lead Mentor & Director",
    },
    {
      name: "Mr. Sayyam Agarwal",
      image: img,
      Experience: 8,
      Certification: 7,
      xyz: "xyz",
      content: "Mentor",
    },
  ];
  return (
    <>
      <div className="page4">
        <div className="Header">
          <div className="Heading">
            <div className="rightText">
              <span> Our EXPERT TRAINER </span>
            </div>
          </div>

          <div className="subHeading">
            <span>
            Powering your crypto journey with insights and guidance from our exceptional team of expert trainers.
            </span>
          </div>
        </div>

        <div className="contentSlider">
          {/* <OurTrainer items={items} /> */}
          <OurExpertTrainer />
        </div>
      </div>
    </>
  );
}

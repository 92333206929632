import React from 'react';

const PopUp = () => {
  // Function to prevent default behavior
  const preventDefault = (event) => {
    event.preventDefault();
  };

  return (
    <div style={styles.container} onClick={preventDefault}>
      <p style={styles.text}>For a better experience, please open this website on your mobile device.</p>
    </div>
  );
};

const styles = {
  container: {
    position:"absolute",
    top:"20%",
    left:"29%",
    height:"70vh",
    zIndex:"10000",
    backgroundColor: '#f0f0f0',
    padding: '20px',
    textAlign: 'center',
    pointerEvents: 'none', 
    display: window.innerWidth>700?"block":"none"

  },
  text: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  },
};

export default PopUp;
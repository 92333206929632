import { useRef, useEffect, useState } from "react";
import React from "react";
import "../styles/page7.scss";
import TestimonialPost from "./TestimonialPost";
function Testimonial({ items }) {
  const carouselTrack = useRef();
  const scrollProgress = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (carouselTrack.current) {
      carouselTrack.current?.addEventListener("scroll", function () {
        const scrollWidth =
          carouselTrack.current.scrollWidth - carouselTrack.current.clientWidth;
        const scrolled = carouselTrack.current.scrollLeft;
        const progress = (scrolled / scrollWidth) * 100;
        scrollProgress.current.style.width = progress + "%";
      });
    }
  });

  useEffect(() => {
    if (carouselTrack.current) {
      let isDown = false;
      let startX;
      let scrollLeft;

      carouselTrack.current.addEventListener("mousedown", (e) => {
        isDown = true;
        carouselTrack.current.classList.add("active");
        startX = e.pageX - carouselTrack.current.offsetLeft;
        scrollLeft = carouselTrack.current.scrollLeft;
      });
      carouselTrack.current.addEventListener("mouseleave", (_) => {
        isDown = false;
        carouselTrack.current.classList.remove("active");
      });
      carouselTrack.current.addEventListener("mouseup", (_) => {
        isDown = false;
        carouselTrack.current.classList.remove("active");
      });
      carouselTrack.current.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - carouselTrack.current.offsetLeft;
        const SCROLL_SPEED = 3;
        const walk = (x - startX) * SCROLL_SPEED;
        carouselTrack.current.scrollLeft = scrollLeft - walk;
      });
    }
  }, []);

  const gridWidth = window.innerWidth>1064?"38%": "80%";

  return (
    <div className="mobile-carousel-container">
      <div
        className="carousel-track"
        ref={carouselTrack}
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${items?.length || 10}, ${gridWidth})`,
          gridTemplateRows: "1fr",
          gridRowGap: window.innerWidth < 800 ? "0rem" : "1rem",
        }}
      >
        {items.map((item, index) => (
          <div className="carousel-slide" key={index}>
            <TestimonialPost post={item} />
          </div>
        ))}
      </div>

      <div className="scroll-container">
        <div className="scroll-progress" ref={scrollProgress}></div>
        <div className="scroll-content"></div>
      </div>
    </div>
  );
}

export default Testimonial;

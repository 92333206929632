import "./App.scss";
import whatsapp from "./assets/app/Whatsapp.png";
import React, { useState, useEffect } from "react";
import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import Page4 from "./components/Page4";
import Page5 from "./components/Page5";
import Page6 from "./components/Page6";
import Page7 from "./components/Page7";
// import Page8 from "./components/page8";
import Page8 from "./components/Footer.jsx"
import Page9 from "./components/page9";
import Dialog from "./components/Dialog/Dialog";
// import OurExpertUpdated from "../src/components/ourExpertTrainerUpdated.jsx";
import DesktopPage4 from "./components/desktopViewTestimonials.jsx"
import PopUp from "./components/PopUp.jsx"
// --------------------------------------
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import PrivacyPolicy from '../src/components/privacyPolicy/PrivacyPolicy';
import PrivacyPolicy from "../src/components/privacyPolicy/privacyPolicy.jsx"

function Page() {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(!isOpen);
  };
  // ---------------------------------
  const [phoneView, setPhoneView] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {phoneView && screenWidth > 1024 && 
      <div className="popup-screen">
        <div className="popup-box">
          <p>For the best experience, switch to mobile version.</p>
          <span className="ok-popup" onClick={() => setPhoneView(false)}>ok</span>
        </div>
      </div>
    }



      <div className="App" style={{ background: "#F5F5F5" }}>
        {/* <PopUp/> */}
        <Page1></Page1>
        <Page2></Page2>
        <Page3 openDialog={openDialog}></Page3>
        <Page4></Page4>
        <DesktopPage4 />
        <Page5></Page5>
        <Page6 openDialog={openDialog}></Page6>
        <Page7 style={{ background: "#F5F5F5" }}></Page7>
        <Page8></Page8>
        {/* <Page9></Page9> */}
        <div className="buttons-align">
          {/* <div
          className="form"
          onClick={() => {
            openDialog();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <g clip-path="url(#clip0_320_15)">
              <path
                d="M22.4997 19.9062L18.669 23.7369C18.3396 24.0657 17.8368 24.1268 17.4457 23.885C16.0085 22.9971 14.655 21.8999 13.3978 20.6042C12.2342 19.4074 11.1523 18.0413 10.1575 16.5125C9.88649 16.0947 9.93033 15.5694 10.2651 15.2347L14.0466 11.4531L5.59353 3L2.05171 6.54183C-0.00777138 8.60064 -0.580919 11.7354 0.625148 14.3427C2.56176 18.5294 5.17646 22.3396 8.41013 25.5932C11.5933 28.7983 15.3776 31.4648 19.7037 33.4193C22.252 34.5709 25.3356 33.9759 27.3758 31.9363L30.9528 28.3593L22.4997 19.9062Z"
                fill="#FF641A"
              />
              <path
                d="M30.9528 28.3591L27.3758 31.9361C25.3356 33.9757 22.252 34.5707 19.7037 33.4191C15.3776 31.4646 11.5933 28.7981 8.41013 25.593L13.3978 20.604C14.655 21.8997 16.0085 22.9969 17.4457 23.8848C17.8368 24.1266 18.3396 24.0655 18.669 23.7367L22.4997 19.906L30.9528 28.3591Z"
                fill="#F03800"
              />
              <path
                d="M25.0341 0C20.9662 0 17.4111 2.69173 16.3884 6.5457C15.8298 8.65034 16.0005 10.7762 16.8811 12.6949C17.457 13.9501 17.2663 15.3249 16.395 16.1962L14.6596 17.9316H24.6555C24.7824 17.9316 24.9085 17.929 25.0341 17.9243C29.8231 17.7357 33.8664 13.9827 33.9965 9.21353C34.134 4.16478 30.0788 0 25.0341 0Z"
                fill="#FFF7CC"
              />
              <path
                d="M33.9965 9.21353C33.8664 13.9827 29.8231 17.7357 25.0341 17.9243V0C30.0788 0 34.134 4.16478 33.9965 9.21353Z"
                fill="#FFE6B3"
              />
              <path
                d="M20.0531 7.96973H30.0151V9.96213H20.0531V7.96973Z"
                fill="#766E6E"
              />
              <path
                d="M22.0455 3.98486H28.0227V5.97726H22.0455V3.98486Z"
                fill="#766E6E"
              />
              <path
                d="M22.0455 11.9546H28.0227V13.947H22.0455V11.9546Z"
                fill="#766E6E"
              />
              <path
                d="M25.0341 3.98486H28.0227V5.97726H25.0341V3.98486Z"
                fill="#5B5555"
              />
              <path
                d="M25.0341 11.9543H28.0227V13.9467H25.0341V11.9543Z"
                fill="#5B5555"
              />
              <path
                d="M25.0341 7.96973H30.0151V9.96213H25.0341V7.96973Z"
                fill="#5B5555"
              />
            </g>
            <defs>
              <clipPath id="clip0_320_15">
                <rect width="34" height="34" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div> */}

          <a
            href="https://wa.me/919516814034"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="bate">
              <img src={whatsapp} alt="whatsapp" />
            </div>
          </a>
        </div>

        <Dialog
          isOpen={isOpen}
          openDialog={() => {
            openDialog();
            console.log("hiiiii");
          }}
        />
      </div>
      {/* <Router>
      <Routes> 
        <Route exact path="/" element={<Home />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> 
      </Routes>
    </Router> */}
    </>
  );
}

export default Page;

import React from "react";
import "../styles/page5.scss";
import cardImg from "../assets/page5/cardsImg.png";
import frameImg from "../assets/page5/updatedFrame.png";
import frame1 from "../assets/page5/frame1.jpg"
import frame2 from "../assets/page5/frame2.jpg"
import frame3 from "../assets/page5/frame3.jpg"
import groupFirst from "../assets/page5/groupFirst.png";
import groupSecond from "../assets/page5/groupSecond.png";
import WhyChooseAng from "../components/whyChooseAngSlider.jsx";

export default function Page5() {
  //   const listItem = [
  //     "Gain from Expert Mentorship, Guiding Your Path to Success in Cryptocurrency. ",
  //     "24/7 Dedicated Support from our ANG Team for Timeless Assistance. ",
  //     "Stay Informed with Regular Market Updates and Clear Your Doubts Anytime. ",
  //   ];

  return (
    <>
      <div className="page5">
        {/* <div className="Head">
          <div className="rightText">
            BENEFITS OF DISCORD <br />
            <span>COMMUNITY</span>
          </div>
        </div> */}

        {/* --------------------------------------- Code-------------------------------------------------- */}

        <h1 className="whyChooseAngHead"> Why Choose ANG?</h1>
        <p className="whyChooseAngPara">
        Our flourishing trading community is a one stop solution for all your tradings journeys requirement.
        </p>

        <hr className="firstHr" />

        <div className="Container">
          {/* <div className="containerList">
            {listItem?.map((elem, index) => {
              return (
                <div className="list">
                  <text>{`0${index + 1}`}</text>

                  <span>{elem}</span>
                </div>
              );
            })}
          </div> */}

          {/* ---------------------------------- First Container ---------------------------- */}
          <div className="firstContainer">
            <h1 className="subHeadSecond">GET REAL WITH CRYPTOCURRENCIES</h1>

            <div className="upperListContent">
              <div className="upperListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                Advantages in regards to decentralisation, security, privacy and transparency due to innovative blockchain technology makes it a top prospect for investment.
                </p>
              </div>

              <div className="upperListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                Due to its ever increasing financial inclusion in current financial and economical model, the future is promising.
                </p>
              </div>
            </div>

            <div className="firstContainerImage">
              <img style={{height:"30vh",objectFit:"cover",borderRadius:"20px"}} src={frame1} alt="groupFirst" />
            </div>
          </div>

          <hr className="firstHr" />

          {/* ------------------------------ Second Container -------------------------------------   */}

          <div className="secondContainer">
            <div className="headSecond">
            INTERACTIVE, INTUITIVE, EFFICIENT
            </div>

            {/* -------------------------------------------------------- */}
            <div className="middleListContent">
              <div className="middleListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                Keeping the admissions limited to 15 in online and 5 for offline batches, we are able to create efficient and interactive sessions throughout the program.

                </p>
              </div>

              <div className="middleListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                Due to keeping a personal touch with each member, students have a better learning curve.
                </p>
              </div>
            </div>

            <div className="secondContainerImage">
              <img style={{borderRadius:"20px"}} src={frame2} alt="groupSecond" />
            </div>

            <hr className="middleSectionHr" />
          </div>

          {/* ---------------------------------- Third Container ---------------------------------------- */}

          <div className="thirdContainer">
            <h1 className="thirdContainerHead">
            BE A PART OF OUR EXCLUSIVE COMMUNITY
            </h1>

            <div className="thirdListContent">
              <div className="thirdListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                Access to our premium discord server with dedicated channels like Mentor’s guidance, Trading setups, lifestyle and much more.

                </p>
              </div>

              <div className="thirdListContentSection">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <ellipse
                    cx="6"
                    cy="6.68178"
                    rx="6"
                    ry="5.964"
                    fill="#303030"
                    fill-opacity="0.28"
                  />
                </svg>

                <p>
                3 months of access to our trading floor for our offline students with facilities like Training room, Traders seating, Gym, Gaming corner and cafeteria.

                </p>
              </div>
            </div>

            {/* ---------------------- SLider ---------------------------- */}

            <WhyChooseAng></WhyChooseAng>
            {/* --------------------- Slider  ------------------------------- */}
            <hr className="thirdHr" />
          </div>

          {/* <div className="listImg">
            <img src={cardImg} alt="frame" />
          </div> */}

          <div className="lastSection">
            <div className="Heads">
              <div className="rightText">
                <span>LIVE SESSIONS</span>

                <div className="headBelowSection">
                  <div className="belowFirstSection">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <ellipse
                        cx="6"
                        cy="6.68178"
                        rx="6"
                        ry="5.964"
                        fill="#303030"
                        fill-opacity="0.28"
                      />
                    </svg>

                    <p>
                    All sessions are taken live by our mentors, allowing students to interact and solve their doubts in the session itself.

                    </p>
                  </div>
                </div>

                <div className="headBelowSection">
                  <div className="belowFirstSection">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <ellipse
                        cx="6"
                        cy="6.68178"
                        rx="6"
                        ry="5.964"
                        fill="#303030"
                        fill-opacity="0.28"
                      />
                    </svg>

                    <p>
                    Access to lifetime Live Market Analysis and Live Trading allowing traders to stay connected with the community and growing together with other traders.

                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="listImg">
              <img style={{height:"30vh",objectFit:"cover",borderRadius:"20px",width:"70%"}} src={frame3} alt="frame" />
            </div>

            <div className="enquiryButtonDiv">
              <a
                href="https://wa.me/919516814034"
                target="_blank"
                style={{ textDecoration: "none", marginBottom: "28px" }}
              >
                <span class="enquiryButton">JOIN OUR COMMUNITY</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

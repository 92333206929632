import React, { useState } from "react";
import "./Dialog.scss";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import IncorrectDialog from "./IncorrectDialog";

const Dialog = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(!isOpen);
  };

  const [verifyOptState, setVerifyOtpState] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    age: "",
    occupation: "",
    OTP: "",
    agreementCheckbox: false,
  });

  const occupation = [
    "Student",
    "Working Professional",
    "Self Employed",
    "Other",
  ];

  const [selectedDropdown, setSelectedDropdown] = useState(false);
  const showDropdown = (dropdownIndex) => {
    setSelectedDropdown(dropdownIndex);
  };

  const [circle, setCircle] = useState(false);

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "age" || name === "email") {
  //     return; // Skip updating formData for age and email
  //   }
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleAgreementCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, agreementCheckbox: checked });
  };

  const [otpSend, setOtpSend] = useState(false);

  const [Verify, setVerify] = useState(false);

  const [code, setCode] = useState(null);

  const sumbit = () => {
    if (!formData?.number) {
      // || !formData?.email
      setMessage("Enter form Details first");
      openDialog();
      return;
    } else {
      const min = 1000; // Minimum 4-digit number
      const max = 9999; // Maximum 4-digit number
      const pin = Math.floor(Math.random() * (max - min + 1)) + min;
      setCode(pin);

      axios
        .get(
          `https://api.authkey.io/request?authkey=d5503b0990fd670f&mobile=${formData.number}&country_code=91&sid=10942&company=ANG%20Account&otp=${pin}`
        )
        .then((response) => {
          console.log(response);
          setOtpSend(true);
          setVerifyOtpState(true);
          openDialog();
          setTimeout(() => {
            setIsOpen(false);
            setVerifyOtpState(false);
          }, 3000);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
  };

  const emailLead = () => {
    setCircle(true);
    // !formData.email ||
    if (!Verify) {
      setMessage("Please Enter valid OTP");
      // !formData.email
      //   ? setMessage("Enter form Details first")
      //   : setMessage("Please Enter valid OTP");

      openDialog();
      setCircle(false);
      return;
    } else {
      axios
        .post(`https://mailcustom.vuitechnology.in/`, {
          email: `admissions@aspirenowglobal.com`,

          // email: `akshay.hans@vuilive.com`,
          website: `ANG Website Lead: ${formData.name}`,
          htmlInput: `Hey, My name is ${formData.name} and please feel free to contact me on ${formData.number}`,
        })
        .then((response) => {
          console.log(response);
          setCircle(false);

          props.openDialog();

          setMessage("Form Submit Successfully");

          openDialog();
          setFormData({
            name: "",
            email: "",
            number: "",
            age: "",
            occupation: "",
            OTP: "",
            agreementCheckbox: false,
          });
          setVerifyOtpState(false);
          setOtpSend(false);

          setVerify(false);

          setCode(null);
        })
        .catch((error) => {
          setCircle(false);
          console.error("Error fetching data: ", error);
        });
    }
  };

  return (
    <div className="dialog-container">
      {props.isOpen && (
        <div className="dialog">
          <div className="dialog-content">
            <button onClick={props.openDialog} className="button-dialog">
              {/* <div className="upperPopUpDiv">Fill the form details</div> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.3139 9.571C24.6611 9.22387 24.6611 8.66106 24.3139 8.31392L23.6857 7.68567C23.3385 7.33854 22.7757 7.33854 22.4286 7.68567L16.6283 13.4859C16.2812 13.8331 15.7184 13.8331 15.3713 13.4859L9.571 7.68567C9.22387 7.33854 8.66106 7.33854 8.31392 7.68567L7.68567 8.31392C7.33854 8.66106 7.33854 9.22387 7.68567 9.571L13.4859 15.3713C13.8331 15.7184 13.8331 16.2812 13.4859 16.6283L7.68567 22.4286C7.33853 22.7757 7.33854 23.3385 7.68567 23.6857L8.31392 24.3139C8.66106 24.6611 9.22387 24.6611 9.571 24.3139L15.3713 18.5137C15.7184 18.1665 16.2812 18.1665 16.6283 18.5137L22.4286 24.3139C22.7757 24.6611 23.3385 24.6611 23.6857 24.3139L24.3139 23.6857C24.6611 23.3385 24.6611 22.7757 24.3139 22.4286L18.5137 16.6283C18.1665 16.2812 18.1665 15.7184 18.5137 15.3713L24.3139 9.571Z"
                  fill="slategrey"
                />
              </svg>
            </button>
            <h1 className="UpperPopUpHead">Fill the form details</h1>
            <fieldset>
              {/* <legend> Name*</legend> */}
              <input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Name*"
                value={formData.name}
                onChange={handleChange}
              />
            </fieldset>
            {/* <fieldset>
              <legend> Email*</legend>
              <input
                type="text"
                id="email"
                name="email"
                required
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </fieldset> */}
            {/* <fieldset>
              <legend> Age*</legend>
              <input
                type="text"
                id="age"
                name="age"
                required
                placeholder="Age"
                value={formData.age}
                onChange={handleChange}
              />
            </fieldset> */}
            {/* --------------------------------------- Dropdown -------------------------------------------- */}
            {/* <fieldset>
              <select
                id="building"
                name="building"
                required
                value={formData.occupation}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Occupation
                </option>

                <option value="student">Student</option>
                <option value="workingProfessional">
                  Working Professional
                </option>
                <option value="selfEmployed">Self Employed</option>
                <option value="other">Other</option>
              </select>
            </fieldset> */}

            <div
              className="dropField"
              style={{ width: "100%" }}
              onClick={(e) => {
                showDropdown(!selectedDropdown);
              }}
            >
              <fieldset>
                <div
                  id="workspaceType"
                  name="workspaceType"
                  className="setDrop"
                  style={{ padding: "0px 10px 0px 0px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "24px",
                      // paddingTop: "7px",
                      padding: "5px 0px 0px 0px",
                    }}
                    onClick={(e) => {
                      showDropdown(!selectedDropdown);
                    }}
                    disabled
                    selected
                  >
                    <div
                      id="workspaceType"
                      name="workspaceType"
                      className="setDrop"
                    >
                      <h1
                        disabled
                        selected
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.79169rem",
                          fontWeight: "500",
                          color: "rgba(0, 0, 0, 0.7)",
                          opacity: 1,
                          textTransform: "capitalize",
                          borderColor: "#E8E8E8",
                          opacity: "0.5",
                        }}
                      >
                        {formData.occupation
                          ? formData.occupation
                          : "Occupation"}
                      </h1>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                    >
                      <path d="M9 11L17.6603 0.5H0.339746L9 11Z" fill="black" />
                    </svg>
                  </div>

                  <div
                    className="dropDo-content"
                    style={{
                      display: selectedDropdown === true ? "block" : "none",
                    }}
                  >
                    {occupation?.map((element) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "1px solid #e5e5e5",
                          }}
                          onClick={() => {
                            const obj = {
                              ...formData,
                              occupation: element,
                            };
                            setFormData(obj);

                            showDropdown(!selectedDropdown);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <label style={{ fontSize: "14px" }}>
                              {element}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* )} */}
                </div>
              </fieldset>
            </div>

            {/* ------------------------------------------ Dropdown End -------------------------------------- */}
            <fieldset>
              {/* <legend> Mobile Number*</legend> */}
              <input
                type="text"
                id="number"
                name="number"
                required
                placeholder="Mobile Number*"
                value={formData.number}
                onChange={handleChange}
              />
            </fieldset>
            <div className="otp">
              <div className="int1">
                <input
                  className="int"
                  type="text"
                  id="otp"
                  name="OTP"
                  required
                  placeholder="OTP"
                  value={formData.OTP}
                  onChange={handleChange}
                />
              </div>

              {Verify ? (
                <div
                  className="button2"
                  onClick={() => {
                    sumbit();
                  }}
                >
                  <span>
                    Verified
                    <div style={{ padding: "0px 4px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M7.49992 13.5001L3.99992 10.0001L2.83325 11.1667L7.49992 15.8334L17.4999 5.83341L16.3333 4.66675L7.49992 13.5001Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </span>
                </div>
              ) : (
                <></>
              )}

              {Verify ? (
                <></>
              ) : !otpSend ? (
                <div
                  className="button1"
                  onClick={() => {
                    sumbit();
                  }}
                >
                  <text>Send</text>
                </div>
              ) : (
                <div
                  className="button1"
                  onClick={() => {
                    console.log(code, formData.OTP);
                    if (code && code == formData.OTP) {
                      setVerify(true);
                    } else {
                      setMessage("Please Enter valid OTP");
                      openDialog();
                    }
                  }}
                >
                  <text> Verify</text>
                </div>
              )}

              {!Verify ? (
                <div
                  className="button1"
                  onClick={() => {
                    sumbit();
                  }}
                >
                  <text>Resend</text>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="aggriment">
              <input
                type="checkbox"
                id="agreementCheckbox"
                checked={formData.agreementCheckbox}
                onChange={handleAgreementCheckboxChange}
              />

              <p>
                Receive information & updates over Whatsapp
                <div
                  style={{
                    padding: "0px 12px",
                    display: "inline",
                    width: "15px",
                    height: "15px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_158_3289)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.49783 0C3.36321 0 0 3.36415 0 7.49993C0 9.14014 0.528951 10.6613 1.42813 11.8959L0.493622 14.6824L3.37639 13.7611C4.56206 14.5459 5.97701 15 7.50217 15C11.6368 15 15 11.6357 15 7.50007C15 3.36428 11.6368 0.000123978 7.50217 0.000123978L7.49783 0ZM5.40401 3.80963C5.25859 3.46133 5.14837 3.44815 4.92805 3.43919C4.85304 3.43483 4.76944 3.43048 4.67676 3.43048C4.39014 3.43048 4.09046 3.51423 3.9097 3.69939C3.68939 3.92425 3.14276 4.44887 3.14276 5.52475C3.14276 6.60063 3.92737 7.64117 4.03311 7.78676C4.14333 7.9321 5.56274 10.172 7.76665 11.0848C9.49011 11.7991 10.0015 11.7329 10.3938 11.6492C10.9668 11.5257 11.6853 11.1023 11.8661 10.591C12.0468 10.0794 12.0468 9.64287 11.9938 9.55029C11.941 9.4577 11.7954 9.40494 11.5751 9.29457C11.3548 9.18432 10.2836 8.65521 10.0808 8.58465C9.88236 8.50974 9.69289 8.53624 9.54311 8.74791C9.33151 9.04332 9.12438 9.34322 8.95681 9.52391C8.82457 9.66502 8.60848 9.68268 8.42785 9.60765C8.18539 9.50636 7.50666 9.26806 6.66906 8.52293C6.02105 7.94542 5.5803 7.2268 5.45254 7.01077C5.32465 6.7904 5.43934 6.66235 5.5406 6.54339C5.65082 6.40663 5.75657 6.3097 5.86679 6.18177C5.97701 6.05398 6.03872 5.98778 6.10926 5.83783C6.18427 5.69237 6.13127 5.54242 6.0784 5.43217C6.02553 5.32192 5.58477 4.24603 5.40401 3.80963Z"
                        fill="#67C15E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_158_3289">
                        <rect width="15" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </p>
            </div>
            <div className="buttonDownload">
              {circle ? (
                <div className="buttonCircle">
                  <CircularProgress
                    style={{ color: "#828282" }}
                    size={32}
                    color="secondary"
                    fourColor={false}
                    variant="indeterminate"
                  />
                </div>
              ) : (
                <div
                  className="button"
                  onClick={() => {
                    if (!Verify) {
                      // setMessage("Please Enter valid OTP");
                      setMessage("Please Fill the Details");
                      openDialog();
                    } else if (!formData?.name) {
                      setMessage("Please Enter Your Name");
                      openDialog();
                    } else {
                      emailLead();
                    }
                  }}
                >
                  <span>Submit</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <IncorrectDialog
        isOpen={isOpen}
        message={message}
        verifyOptState={verifyOptState}
        openDialog={() => {
          openDialog();
          console.log("hiiiii");
        }}
        Verify={Verify}
      />
    </div>
  );
};

export default Dialog;

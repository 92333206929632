import React from "react";
import "../styles/page7.scss";
import Testimonial from "./Testimonial";

export default function Page7() {
  const items = [
    {
      name: "Rahul Rajput",
      content: `I am writing to tell how grateful I am for your mentorship over the past years. Learning from you and your team has greatly enhanced my capabilities and skill set and has brought about a substantial change in my trading Journey. When I first started trading course, I never imagined that I could make as much progress as I have made now. I have now moved up to good trader, and that has been only possible because of @YashGupta , @Vivek M 🙏🙏

      Your training in team management has given me a fresh perspective on how to improve Money Management , along with accepting Correct Path. Before learning from ANG, I struggled to manage my trading Skills. But, thanks to the time i spent learning and best practice, I have gained the confidence.
      
      To have you all @YashGupta @Vivek M as a mentor has changed my life completely, and for the better. I truly appreciate everything done by @Tushar Dudi @Abin Paul @Roy✅ for me so far and hope to continue learning from ANG. If there is any way I can help you with anything, please let me know. It would be an honour and an absolute pleasure to support you as a team member. `,
      type: "Student",
    },
    {
      name: "Suryah",
      content: `Hello Guys! Myself Surya ! from Tamil Nadu. I started my journey last year.I learned everything from ANG, Mentally and financially I was broke because of my past business. At that time, I came out from my family and stayed 4 months in Gurgaon.Everything will new to me (Language, Food,People)when I was there in Haryana.Finally it's worthy! Now I set my career in digital world .Thanks for everything @Vivek M @SayyamAg @Roy✅ @Vivek M .They works very dedicatedly on each and every student to make them understand the entire process. The content of the course is very simplified. It's just been a month and I'm deeply getting confident in the trades I take.

      Main thing I learned here Psychology, management, especially 'The Trader’s Lifestyle’.`,
      type: "Business Man",
    },
    {
      name: "Habib",
      content: `Hey hi guys , this is Habib from Tamil Nadu, former ANG student, i really want to say it, i came to ANG with the zero knowledge of trading, and i really want to know about trading !! In that time i got about ANG!! After

      joining the community of ANG! They guide me and teach a each and every thing about trading, and now I’m able to take any of the trade with my own, i got a proper knowledge ! With my own analysis ! From past 10 months i make a consistent profit with my own analysis that make me something special feeling all the credits go to mr Sayyam sir who was a mentor teach me everything, and Mr Yash Gupta sir, Vivek sir and Roy thanks to all, Im feeling lucky to learn my all knowledge about psychology, money management, and etc..once again thanks ANG for make me this level.`,
      type: "Trader",
    },
    {
      name: "Jay Mehta",
      content: `I feel Yash Gupta Sir, you are my Krishna! And one day after all the failures i have faced in my life, i wish i can be a successful trader and make you feel proud. I have never talked to him nor met him. But I definitely will come to see you the day i get my first trade profitable and get your best wishes and blessings.

      Thank you for everything. Thank you for being the Krishna for so many students like us who are stuck and being a guiding light for them. I just saw this video and the person whom I could see and imagine was none other than you @YashGupta ! Thank you 🙏🏻
      
      Along with this i would like to thank everyone in ANG @Vivek M @SayyamAg @Roy for being there for everything and teaching us!🙏🏻`,
      type: "Student",
    },
    {
      name: "Mangal Singh",
      content: `Hello, I want to express my sincere thank to all the mentors. They have good knowledge & their dedicated mentorship helps students understanding the concept clearly. As u say u never left anyone behind. I also experienced that, When i enrolled for the course i have attended only few classes but missed some classes due to some reason so i was out of sequence but @Roy✅ added me in a new group so that i can learn it again. Special thanks to the ROY for quick response and support always.

      Once again, Thank you mentors @YashGupta @Vivek M @SayyamAg for making this learning an enriching experience.
      
      @YashGupta your analysis keep us(New members) updated and help to understand the market trend.`,
      type: "Student",
    },
    {
      name: "Rajneesh",
      content: `Thanks a ton to ANG Community specifically

      Roy sir💐, Vivek sir💐, Sayyam sir🤗 and
      
      Yash Sir💐 for giving me knowledge on crypto (trading) and still learning on it by going through discord( ANG)
      
      My learning experience with all mentors is so unique...
      
      (धन्यवाद ❤️)`,
      type: "Student",
    },
    {
      name: "Sidak",
      content: `I started investing in crypto in the peak month of 2021 bull run with an investment of 500 dollars and didn't knew anything about trading. In march 2022 i joined a group chat of friends who were doing futures in crypto. Like many i lost all my portfolio and to recover that i added more money and lost again. I have been following @angtradingcommunity @YashGupta and i decided to learn the technicals and fundamentals . When i joined the community i was in total loss of more than 5k dollars and today i have recovered all and i am profitable 📈 this was only possible because of @YashGupta @Vivek M @SayyamAg @Roy✅ @Rahul B. if anyone wants to start the crypto journey ANG is the best place. ♥️`,
      type: "Student",
    },
    {
      name: "Monil",
      content:
        "I recently finished the crypto trading course and found it to be a wonderful introduction to the world of cryptocurrency trading. The course curriculum was quite thorough, covering all of the major components of trading, such as technical analysis, risk management, and market psychology.The mentor was extremely knowledgeable and gave several useful insights as well as practical advise on how to monitor market trends and manage risk.Learn a lot about maintaining your composure in a bear market from Rahul and Yash Sir.",
      type: "Student",
    },
    {
      name: "Sai Aleti",
      content: `
        First of all, Thank you very much for this boost of encouragement with this wolf trader from aspire now global family ❤️ .

I have very little time after managing my corporate job to trade and only after joining ANG and learning about the money moving in markets I realised there are more fool proof ways to live a better life and trading is my tool of choice.

Taking part in those online and live interactive sessions with @YashGupta sir, @Vivek M sir, and @SayyamAg sir helped me to have better view of how the economies move around the globe. Thank you very much @YashGupta sir @Vivek M sir for changing my mindset about money and the support I received with all my doubts from @duditushar sir @purnendukhatri sir and @Roy✅ sir made me a better trader day by day and i hope this tutor bonding to always continue forever.

Mostly after my day's work I'm disinterested to watch the market movement but those regular market updates and live market situation in zoom meetings with @Vivek M sir, @YashGupta sir and @SayyamAg sir keeps me active to analyse and trade.

Now with this moment I am very much confident with what I'm doing with life, and will only move forward to get better in life along side with you all.

Once again , Thanking you all @everyone ❤️ 🙏

Cheers to ANG.
        
        `,
      type: "Student",
    },
    {
      name: "Kuntal",
      content:
        "thank you @YashGupta @Vivek M @Roy✅ @SayyamAg @purnendukhatri @duditushar its never possible without you each one has support me a lot, i still remember the day when i was came ANG at 28th November i never live in other city in my entire life but i decide to move , in starting it was hard for me to suddenly shifting in other city but its worth it , i have only 1 thought in my mind that until i am not complete trader i am not returning to home still i have to do lot of improvement ,learning never stop just keep head up and make your parents retire first again thank you all of mentors to giving the best knowledge ,for someone its just community but for me this was family❤️",
      type: "Student",
    },
    {
      name: "Naseer",
      //   content:"",
      content:
        "It’s been almost 3 weeks since I joined ANG. It’s been a great experience so far.I attended the first 2 weeks of my batch but missing some classes due to a serious conflict of schedule. I was switched to the next batch two weeks later & had to go from scratch again (in terms of classes).The thing which I admired the most honestly & really appreciated is the “Kaizen” mindset of ANG Mentors & Team (Kaizen= Japanese business philosophy of continuous improvement of working practices, personal efficiency, etc.). Today, we had a lecture with @Vivek M & I was truly surprised of the amount of improvement;in terms of course content & approach of topics from our immediate previous batch. That’s really great to witness & I hope that ANG team keeps up this improvement mentality.",
      type: "Student",
    },
  ];
  return (
    <>
      <div className="page7">
        <div className="Header">
          <div className="Heading">
            <div className="rightText">
              <span>
                TESTIMO<text>NIALS</text>{" "}
              </span>
            </div>
          </div>

          <div className="subHeading">
            <span>
              Stories of Excellence of our <br /> learners{" "}
            </span>
          </div>
        </div>

        <div className="contentSlider">
          <Testimonial items={items} />
        </div>
      </div>
    </>
  );
}

import React from "react";
import "../styles/page3.scss";
import discordLogo from "../assets/page3/discord-logo.png";
export default function Page3(props) {
  const program = [
    {
      heading: "50+ Hours of Live sessions. (Technical Analysis)",
      icon: (
        <svg
          width="64px"
          height="64px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g opacity="0.5">
              {" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.46689 4.39207C5.75949 4.68526 5.75902 5.16013 5.46583 5.45273C3.78722 7.128 2.75 9.44218 2.75 12C2.75 14.5878 3.81163 16.9262 5.52503 18.6059C5.82082 18.8959 5.82554 19.3707 5.53557 19.6665C5.24561 19.9623 4.77076 19.967 4.47497 19.677C2.48564 17.7269 1.25 15.0071 1.25 12C1.25 9.02783 2.45721 6.33616 4.40623 4.39102C4.69941 4.09842 5.17429 4.09889 5.46689 4.39207ZM18.6164 4.46446C18.9122 4.17449 19.387 4.17921 19.677 4.475C21.5771 6.41326 22.75 9.07043 22.75 12C22.75 14.9645 21.5491 17.6499 19.609 19.5938C19.3164 19.887 18.8415 19.8875 18.5484 19.5949C18.2552 19.3023 18.2547 18.8274 18.5473 18.5342C20.2182 16.86 21.25 14.5512 21.25 12C21.25 9.47873 20.2422 7.1943 18.6059 5.52507C18.3159 5.22928 18.3206 4.75443 18.6164 4.46446ZM8.30923 7.48757C8.59226 7.79001 8.57652 8.26462 8.27408 8.54765C7.32517 9.43564 6.75 10.6502 6.75 11.9822C6.75 13.3297 7.33869 14.5573 8.30756 15.4479C8.61251 15.7282 8.63248 16.2026 8.35216 16.5076C8.07185 16.8125 7.59739 16.8325 7.29244 16.5522C6.03967 15.4006 5.25 13.7824 5.25 11.9822C5.25 10.203 6.02148 8.60128 7.24916 7.45242C7.5516 7.16939 8.02621 7.18513 8.30923 7.48757ZM15.7429 7.52559C16.0292 7.22626 16.5039 7.21571 16.8033 7.50202C18.0005 8.64714 18.75 10.2286 18.75 11.9822C18.75 13.7568 17.9825 15.3548 16.7604 16.503C16.4586 16.7867 15.9839 16.7719 15.7003 16.47C15.4167 16.1681 15.4315 15.6935 15.7333 15.4099C16.6778 14.5225 17.25 13.3108 17.25 11.9822C17.25 10.6692 16.6911 9.47046 15.7664 8.58599C15.4671 8.29968 15.4566 7.82492 15.7429 7.52559Z"
                fill="#00208a"
              ></path>{" "}
            </g>{" "}
            <path
              d="M13.6563 10.4511C14.5521 11.1088 15 11.4376 15 12C15 12.5624 14.5521 12.8912 13.6563 13.5489C13.4091 13.7304 13.1638 13.9014 12.9384 14.0438C12.7407 14.1688 12.5168 14.298 12.2849 14.4249C11.3913 14.914 10.9444 15.1586 10.5437 14.8878C10.1429 14.617 10.1065 14.0502 10.0337 12.9166C10.0131 12.596 10 12.2817 10 12C10 11.7183 10.0131 11.404 10.0337 11.0834C10.1065 9.94977 10.1429 9.38296 10.5437 9.1122C10.9444 8.84144 11.3913 9.08599 12.2849 9.57509C12.5168 9.70198 12.7407 9.83123 12.9384 9.95619C13.1638 10.0986 13.4091 10.2696 13.6563 10.4511Z"
              fill="#00208a"
            ></path>{" "}
          </g>
        </svg>
      ),
    },
    {
      heading: "Recorded sessions for revision",
      icon: (
        <svg
          height="64px"
          width="64px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLineCap="round"
            strokeLineJoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <circle
              style={{ fill: "#e6e6e6" }}
              cx="256"
              cy="256"
              r="256"
            ></circle>{" "}
            <path
              style={{ fill: "#C6CBD6" }}
              d="M70.924,432.776h370.234c-46.598,48.817-112.344,79.142-185.076,79.142 c-72.814,0-138.478-30.408-185.076-79.142H70.924z"
            ></path>{" "}
            <path
              style={{ fill: "#8c8c8c" }}
              d="M42.571,223.291h281.312c9.204,0,16.765,7.561,16.765,16.765V413.38H25.723V240.057 c0-9.204,7.561-16.765,16.765-16.765H42.571z"
            ></path>{" "}
            <path
              style={{ fill: "#FEFEFE" }}
              d="M37.147,401.957h292.078v-161.9c0-2.959-2.466-5.424-5.424-5.424H42.489 c-2.959,0-5.424,2.465-5.424,5.424v161.9H37.147z"
            ></path>{" "}
            <path
              style={{ fill: "#000000" }}
              d="M82.594,341.059c5.095-8.958,13.067-19.477,4.931-35.339c-16.19-58.103,43.146-66.322,51.529-43.557 c13.56,5.424,13.231,35.092,6.246,50.625c-0.329,6.41-5.342,11.67,6.082,28.189C121.22,346.073,103.551,344.347,82.594,341.059z"
            ></path>{" "}
            <path
              style={{ fill: "#ecd9c1" }}
              d="M107.495,324.294c-0.493,9.862-4.027,12.985-8.876,15.533c-2.465,2.219-20.053,3.534-26.545,11.588 c-1.151,1.397-0.082,44.954-1.151,47.502h5.999l3.205-16.108l-0.247,16.108l68.705,0.247l2.876-20.381l3.452,19.724 c5.999-0.822,11.752-2.301,17.341-4.356c-1.89-15.039-4.931-34.846-9.122-41.831c-5.588-9.369-23.833-10.273-26.134-12.163 c-4.767-2.63-8.136-5.917-8.711-15.779c0,0-7.068,5.506-11.506,4.684c-4.438-0.822-9.451-4.684-9.451-4.684L107.495,324.294z"
            ></path>{" "}
            <path
              style={{ fill: "#FF7069" }}
              d="M104.455,335.142c-1.561,2.137-3.534,3.452-5.835,4.684c-2.465,2.219-20.053,3.534-26.545,11.588 l-3.698,5.999l-10.602,44.625h18.491l3.78-19.231L79.8,402.039h71.828l-0.247-19.231l4.027,19.231h20.71 c-3.452-17.587-9.944-44.872-12.821-49.639c-5.588-9.369-23.833-10.273-26.134-12.163c-2.055-1.151-3.863-2.383-5.26-4.191 l-13.149,25.148l-14.3-25.97V335.142z"
            ></path>{" "}
            <polygon
              style={{ fill: "#FFFFFF" }}
              points="122.699,401.957 122.699,353.551 116.7,364.975 116.7,401.957 "
            ></polygon>{" "}
            <path
              style={{ fill: "#F0B97D" }}
              d="M107.249,327.417c2.63,1.561,6.903,6.328,10.93,6.41c4.027,0.082,9.698-5.917,10.519-6.328 c-0.082-0.575-0.164-1.151-0.164-1.726l-0.082,0.082l0,0l0,0c-0.247,0.164-7.15,5.588-11.506,4.767 c-4.274-0.822-9.122-4.52-9.451-4.767l-0.082-0.082C107.331,326.349,107.331,326.924,107.249,327.417z"
            ></path>{" "}
            <path
              style={{ fill: "#ecd9c1" }}
              d="M96.072,308.515c4.356,9.369,5.999,13.067,11.423,17.258c0,0,5.013,3.863,9.451,4.767 s11.506-4.767,11.506-4.767c5.424-4.191,7.068-7.89,11.423-17.258c1.068,0.575,2.219,1.315,3.041,0.329 c0.904-0.986,5.013-13.971,4.274-15.697c-0.74-1.726-2.219-0.493-2.548,0.164c-0.247,0.329-0.411,1.644-0.74,1.644 c-0.247,0-0.411-0.575-0.822-1.808c-1.233-3.534-3.863-7.232-4.849-19.149c-19.888,19.724-38.379,10.684-44.872,11.999 c-1.644,0.329-1.808,4.438-1.726,6.821c0,0.247,0.493,1.397,0.329,1.479c-0.164,0-0.904-1.068-0.986-1.151 c-6.41-4.191,1.808,15.615,1.972,15.779c0.904,0.986,1.972,0.247,3.041-0.329L96.072,308.515z"
            ></path>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#FFFFFF" }}
                d="M104.455,335.142l14.3,25.97l-1.233,2.383c-2.219-3.123-6.657-13.56-7.479-13.56 c-8.136,0.082-17.176,4.52-13.642-1.808l6.082-10.848l2.055-2.219L104.455,335.142z"
              ></path>{" "}
              <path
                style={{ fill: "#FFFFFF" }}
                d="M131.575,335.389l-8.876,18.08v2.794c0.411-0.247,4.767-7.232,5.178-7.232 c4.027-0.082,12.327,4.027,13.396,1.397c0.164-0.493-0.082-1.233-0.657-2.137l-5.999-9.862L131.575,335.389z"
              ></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#EDEDED" }}
                d="M119.823,367.029c0.904,0,1.644,0.74,1.644,1.644s-0.74,1.644-1.644,1.644 c-0.904,0-1.644-0.74-1.644-1.644S118.919,367.029,119.823,367.029z"
              ></path>{" "}
              <path
                style={{ fill: "#EDEDED" }}
                d="M119.823,371.96c0.904,0,1.644,0.74,1.644,1.644c0,0.904-0.74,1.644-1.644,1.644 c-0.904,0-1.644-0.74-1.644-1.644C118.179,372.7,118.919,371.96,119.823,371.96z"
              ></path>{" "}
            </g>{" "}
            <path
              style={{ fill: "#e6e6e6" }}
              d="M2.301,413.38h361.852v9.698c0,5.342-4.356,9.698-9.698,9.698H12.081 c-5.342,0-9.698-4.356-9.698-9.698v-9.698H2.301z"
            ></path>{" "}
            <polygon
              style={{ fill: "#C6CBD6" }}
              points="148.751,416.257 217.703,416.257 210.306,421.188 156.148,421.188 "
            ></polygon>{" "}
            <g>
              {" "}
              <circle
                style={{ fill: "#e6e6e6" }}
                cx="185.24"
                cy="228.88"
                r="2.712"
              ></circle>{" "}
              <circle
                style={{ fill: "#e6e6e6" }}
                cx="179.488"
                cy="228.88"
                r="1.068"
              ></circle>{" "}
            </g>{" "}
            <polygon
              style={{ fill: "#FED298" }}
              points="314.268,287.065 397.108,286.326 405.573,346.401 412.065,350.182 352.729,454.308 293.229,350.264 307.857,342.95 "
            ></polygon>{" "}
            <path
              style={{ fill: "#6B7F9E" }}
              d="M294.462,349.689L186.72,384.123c0,0-14.053,7.725-18.162,29.339 c-3.287,17.423-5.26,52.186-7.561,80.293C190.418,505.508,222.469,512,256.082,512c99.606,0,185.898-56.871,228.222-139.875 l-69.116-21.861l-120.645-0.493L294.462,349.689z"
            ></path>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#FED298" }}
                d="M439.515,196.664c9.862,2.63,14.957,18.656,11.423,35.75c-3.616,17.094-14.546,28.928-24.408,26.299 c-9.862-2.63-14.957-18.656-11.423-35.75C418.722,205.868,429.653,194.034,439.515,196.664z"
              ></path>{" "}
              <path
                style={{ fill: "#FED298" }}
                d="M280.326,196.5c9.944-2.383,20.628,9.615,23.997,26.792c3.287,17.176-2.055,33.12-11.999,35.503 c-9.944,2.383-20.628-9.615-23.997-26.792C265.04,214.826,270.382,198.883,280.326,196.5z"
              ></path>{" "}
            </g>{" "}
            <path
              style={{ fill: "#000000" }}
              d="M267.834,137.163c-0.904,35.092,21.779,123.932,41.913,153.518c6.739,9.944,90.483,9.944,97.222,0 c20.135-29.504,45.447-118.426,44.543-153.518c-15.615-75.773-169.133-71.006-183.761,0H267.834z"
            ></path>{" "}
            <path
              style={{ fill: "#FFFFFF" }}
              d="M311.227,329.883h86.21c9.698,0,17.669,7.972,17.669,17.669v2.712H293.558v-2.712 C293.558,337.854,301.529,329.883,311.227,329.883z"
            ></path>{" "}
            <path
              style={{ fill: "#83CF8F" }}
              d="M167.653,314.679l10.602-14.546l-1.644-6.246c-0.74-2.137-1.233-4.438-1.233-6.821v-10.848 c0-11.013,8.958-19.97,19.97-19.97h38.544c11.013,0,19.97,8.958,19.97,19.97v10.848c0,11.013-8.958,19.97-19.97,19.97h-38.544 c-1.561,0-3.123-0.247-4.602-0.575l-23.093,8.136V314.679z"
            ></path>{" "}
            <path
              style={{ fill: "#FFFFFF" }}
              d="M244.412,269.56h-55.967v-3.123h55.967V269.56z M218.114,295.777h-29.668v-3.123h29.668V295.777z M244.412,287.065h-55.967v-3.123h55.967V287.065z M244.412,278.354h-55.967v-3.123h55.967V278.354z"
            ></path>{" "}
          </g>
        </svg>
      ),
    },
    {
      heading: "Lifetime access to live ANG Discord serve",
      icon: (
        <svg
          width="64px"
          height="64px"
          viewBox="0 -28.5 256 256"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path
                d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                fill="#5865F2"
                fill-rule="nonzero"
              >
                {" "}
              </path>{" "}
            </g>{" "}
          </g>
        </svg>
      ),
    },
    {
      heading: "Lifetime access to Live Trading/ Market Analysis",
      icon: (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="64px"
          height="64px"
          fill="#000000"
          stroke="#000000"
          strokeWidth="0.00512"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke="#CCCCCC"
            stroke-width="4.096"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <polygon
              style={{ fill: "#70aae1" }}
              points="250.489,368.556 250.489,159.149 298.653,159.149 298.653,143.398 298.653,6.282 26.424,6.282 26.424,504.671 298.653,504.671 298.653,383.941 298.653,368.556 "
            ></polygon>{" "}
            <polyline
              style={{ fill: "#747272" }}
              points="267.242,383.215 267.242,424.049 57.835,424.049 57.835,39.787 267.242,39.787 267.242,143.444 "
            ></polyline>{" "}
            <rect
              x="166.426"
              y="215.564"
              style={{ fill: "#ff0000" }}
              width="56.127"
              height="152.344"
            ></rect>{" "}
            <rect
              x="254.618"
              y="159.432"
              style={{ fill: "#00c717" }}
              width="56.127"
              height="208.475"
            ></rect>{" "}
            <rect
              x="342.82"
              y="95.291"
              style={{ fill: "#ff0000" }}
              width="56.127"
              height="272.616"
            ></rect>{" "}
            <rect
              x="431.022"
              y="23.129"
              style={{ fill: "#00c717" }}
              width="56.127"
              height="344.778"
            ></rect>{" "}
            <rect x="50.509" y="456.507" width="31.411" height="15.706"></rect>{" "}
            <rect x="243.163" y="456.507" width="31.411" height="15.706"></rect>{" "}
            <rect
              x="154.165"
              y="456.507"
              style={{ fill: "#747272" }}
              width="16.753"
              height="15.706"
            ></rect>{" "}
            <rect
              x="106.002"
              y="63.869"
              style={{ fill: "#00c717" }}
              width="64.916"
              height="15.706"
            ></rect>{" "}
            <rect x="178.247" y="63.869" width="16.753" height="15.706"></rect>{" "}
            <rect
              x="202.329"
              y="63.869"
              style={{ fill: "#00c717" }}
              width="16.753"
              height="15.706"
            ></rect>{" "}
            <rect x="226.411" y="63.869" width="16.753" height="15.706"></rect>{" "}
            <path d="M230.401,375.758h-71.832v-168.05h71.832V375.758z M174.275,360.052h40.421V223.413h-40.421V360.052z"></path>{" "}
            <path d="M318.601,375.758h-71.832V151.58h71.832V375.758z M262.474,360.052h40.421V167.286h-40.421V360.052z"></path>{" "}
            <path d="M406.8,375.758h-71.833V87.436h71.832v288.322H406.8z M350.673,360.052h40.421V103.141h-40.421V360.052z"></path>{" "}
            <path d="M494.999,375.758h-71.832V15.272h71.832V375.758z M438.873,360.052h40.421V30.978h-40.421V360.052z"></path>{" "}
            <polygon points="305.982,512 17.001,512 17.001,0 305.982,0 305.982,143.444 290.277,143.444 290.277,15.706 32.706,15.706 32.706,496.294 290.277,496.294 290.277,383.215 305.982,383.215 "></polygon>{" "}
            <polygon points="273.524,432.425 50.506,432.425 50.506,31.411 273.524,31.411 273.524,143.444 257.819,143.444 257.819,47.117 66.211,47.117 66.211,416.72 257.819,416.72 257.819,383.215 273.524,383.215 "></polygon>{" "}
          </g>
        </svg>
      ),
    },
    {
      heading: "24/7 support from ANG Team",

      icon: (
        <svg
          height="64px"
          width="64px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512.00 512.00"
          fill="#ffffff"
          stroke="#ffffff"
          stroke-width="0.00512"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLineCap="round"
            strokeLineJoin="round"
            stroke="#CCCCCC"
            strokeWidth="1.024"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path
                style={{ fill: "#000000" }}
                d="M299.322,205.978c16.095-9.661,36.126-21.684,36.126-51.496c0-21.905-17.82-39.724-39.724-39.724 c-21.905,0-39.724,17.82-39.724,39.724c0,4.875,3.953,8.828,8.828,8.828s8.828-3.953,8.828-8.828 c0-12.169,9.9-22.069,22.069-22.069c12.169,0,22.069,9.9,22.069,22.069c0,18.86-10.472,26.103-27.557,36.359 C274.983,199.997,256,211.391,256,238.345c0,4.875,3.953,8.828,8.828,8.828h61.793c4.875,0,8.828-3.953,8.828-8.828 c0-4.875-3.953-8.828-8.828-8.828h-51.77C277.906,219.352,286.666,213.576,299.322,205.978z"
              ></path>{" "}
              <path
                style={{ fill: "#000000" }}
                d="M423.724,203.034h-8.828v-79.448c0-4.875-3.953-8.828-8.828-8.828s-8.828,3.953-8.828,8.828v79.448 h-33.37l15.543-77.717c0.956-4.781-2.144-9.431-6.925-10.388c-4.783-0.957-9.431,2.144-10.388,6.925l-17.655,88.276 c-0.519,2.593,0.153,5.283,1.83,7.328c1.677,2.045,4.182,3.231,6.827,3.231h44.138v17.655c0,4.875,3.953,8.828,8.828,8.828 c4.875,0,8.828-3.953,8.828-8.828V220.69h8.828c4.875,0,8.828-3.953,8.828-8.828C432.551,206.987,428.599,203.034,423.724,203.034z "
              ></path>{" "}
            </g>{" "}
            <path
              style={{ fill: "#000000" }}
              d="M150.069,114.759c-63.379,0-114.759,51.379-114.759,114.759v203.034h229.517V229.517 C264.828,166.138,213.448,114.759,150.069,114.759z"
            ></path>{" "}
            <path
              style={{ fill: "#fe3434" }}
              d="M512,176.552C512,79.2,432.799,0,335.448,0C270.4,0,210.807,35.605,179.925,92.919 c-2.313,4.292-0.708,9.646,3.584,11.958c4.291,2.313,9.646,0.708,11.959-3.584c27.797-51.59,81.434-83.638,139.98-83.638 c87.616,0,158.897,71.281,158.897,158.897s-71.281,158.897-158.897,158.897c-1.692,0-3.386-0.036-5.081-0.089l11.324-11.324 c3.448-3.447,3.448-9.036,0-12.483c-3.447-3.448-9.036-3.448-12.483,0l-26.483,26.483c-3.448,3.447-3.448,9.036,0,12.483 l26.483,26.483c1.724,1.724,3.982,2.585,6.242,2.585c2.26,0,4.519-0.862,6.242-2.585c3.448-3.447,3.448-9.036,0-12.483 l-11.498-11.497c1.752,0.05,3.506,0.083,5.255,0.083C432.799,353.103,512,273.903,512,176.552z"
            ></path>{" "}
            <path
              style={{ fill: "#ffcc99" }}
              d="M283.841,434.589l-78.77-32.821c-6.579-2.741-10.865-9.17-10.865-16.298v-22.435h-88.276v22.436 c0,7.127-4.286,13.556-10.865,16.298l-78.77,32.821C6.429,438.7,0,448.343,0,459.034v35.31C0,504.095,7.905,512,17.655,512h264.828 c9.75,0,17.655-7.905,17.655-17.655v-35.31C300.138,448.343,293.709,438.7,283.841,434.589z"
            ></path>{" "}
            <path
              style={{ fill: "#fe3434" }}
              d="M283.841,434.589l-63.644-26.518c-13.364,25.064-39.742,42.136-70.129,42.136 s-56.765-17.073-70.129-42.136l-63.644,26.518C6.429,438.7,0,448.343,0,459.034v35.31C0,504.095,7.905,512,17.655,512h264.828 c9.75,0,17.655-7.905,17.655-17.655v-35.31C300.138,448.343,293.709,438.7,283.841,434.589z"
            ></path>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#000000" }}
                d="M16.297,434.589C6.429,438.7,0,448.343,0,459.034v35.31C0,504.095,7.905,512,17.655,512h88.276 c-26.483-44.138-42.295-97.134-42.295-97.134L16.297,434.589z"
              ></path>{" "}
              <path
                style={{ fill: "#000000" }}
                d="M283.841,434.589c9.868,4.111,16.297,13.754,16.297,24.446v35.31c0,9.75-7.905,17.655-17.655,17.655 h-88.276c26.483-44.137,42.295-97.134,42.295-97.134L283.841,434.589z"
              ></path>{" "}
            </g>{" "}
            <path
              style={{ fill: "#fea358" }}
              d="M194.207,363.034h-88.276v22.436c0,7.127-4.286,13.556-10.865,16.298l-12.302,5.127 C176.908,430.9,194.207,363.034,194.207,363.034z"
            ></path>{" "}
            <path
              style={{ fill: "#C04B50" }}
              d="M43.027,468.738L7.593,440.554C2.846,445.409,0,451.974,0,459.034v35.31 C0,504.095,7.905,512,17.655,512h35.31v-22.582C52.966,481.373,49.309,473.764,43.027,468.738z"
            ></path>{" "}
            <path
              style={{ fill: "#F0C087" }}
              d="M143.844,209.469c-19.671,20.583-45.173,35.471-73.823,42.26c-4.348,1.031-7.364,4.958-6.959,9.408 l5.867,64.54c1.058,11.636,7.804,21.995,18.017,27.669l45.974,25.54c10.665,5.924,23.631,5.924,34.296,0l45.974-25.54 c10.214-5.674,16.959-16.033,18.017-27.669l5.867-64.54c0.405-4.45-2.612-8.377-6.959-9.408c-28.65-6.79-54.152-21.677-73.823-42.26 C152.927,205.948,147.211,205.948,143.844,209.469z"
            ></path>{" "}
            <path
              style={{ fill: "#ffcc99" }}
              d="M156.281,209.458c-3.364-3.521-9.069-3.512-12.441,0.001c-11.252,11.721-24.447,21.517-38.984,29.107 c-10.923,5.71-22.581,10.211-34.857,13.158c-4.335,1.041-7.342,4.952-6.94,9.393l5.869,64.561 c1.058,11.637,7.804,21.996,18.018,27.671l45.973,25.54c3.822,2.123,7.945,3.454,12.147,4.055l-13.33-23.995 c-5.375-9.675-8.669-20.366-9.671-31.389l-5.764-63.417c-0.598-6.574,2.496-12.977,8.108-16.452 c9.151-5.667,17.771-12.174,25.66-19.556c23.357,21.858,52.761,36.759,84.538,42.572l0.554,0.102l0.559,0.066l0.463,0.054 l0.89-9.789c0.405-4.45-2.612-8.377-6.959-9.408C201.46,244.939,175.955,230.046,156.281,209.458z"
            ></path>{" "}
            <path
              style={{ fill: "#D7DEED" }}
              d="M220.198,408.071c-13.364,25.064-39.742,42.136-70.129,42.136s-56.765-17.073-70.129-42.136 l-16.305,6.794c16.075,31.434,48.703,52.997,86.433,52.997s70.358-21.564,86.433-52.997L220.198,408.071z"
            ></path>{" "}
            <path
              style={{ fill: "#464655" }}
              d="M237.689,307.552c-0.439,1.5-11.5,36.724-78.793,36.724v17.655c81.655,0,95.285-47.706,95.828-49.741 L237.689,307.552z"
            ></path>{" "}
            <circle
              style={{ fill: "#5B5D6E" }}
              cx="158.897"
              cy="353.103"
              r="8.828"
            ></circle>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#707487" }}
                d="M247.172,264.828h-10.433l-4.815,52.966h15.249c4.875,0,8.828-3.953,8.828-8.828v-35.31 C256,268.78,252.047,264.828,247.172,264.828z"
              ></path>{" "}
              <path
                style={{ fill: "#707487" }}
                d="M63.399,264.828H52.966c-4.875,0-8.828,3.953-8.828,8.828v35.31c0,4.875,3.953,8.828,8.828,8.828 h15.247L63.399,264.828z"
              ></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path
                style={{ fill: "#C04B50" }}
                d="M53.652,419.025c8.16,23.823,21.286,59.261,37.422,92.975h19.62 c-17.519-35.277-32.043-74.578-40.679-99.794L53.652,419.025z"
              ></path>{" "}
              <path
                style={{ fill: "#C04B50" }}
                d="M257.111,468.738l35.433-28.184c4.747,4.855,7.593,11.42,7.593,18.481v35.31 c0,9.75-7.905,17.655-17.655,17.655h-35.31v-22.582C247.172,481.373,250.829,473.764,257.111,468.738z"
              ></path>{" "}
              <path
                style={{ fill: "#C04B50" }}
                d="M246.486,419.025c-8.16,23.823-21.286,59.261-37.422,92.975h-19.62 c17.519-35.277,32.043-74.578,40.679-99.794L246.486,419.025z"
              ></path>{" "}
            </g>{" "}
          </g>
        </svg>
      ),
    },

    // {
    //   heading: "Gain Lifetime Live Trading Access",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="50"
    //       height="46"
    //       viewBox="0 0 50 46"
    //       fill="none"
    //     >
    //       <path
    //         d="M39.6484 0.278809H33.7891H27.9297C22.2763 0.278809 17.5781 4.98051 17.5781 10.7583C17.5781 16.0288 21.5024 20.403 26.4648 21.1311V25.729C26.4648 26.3342 26.821 26.8811 27.3689 27.112C27.9093 27.3425 28.5431 27.2189 28.9654 26.7875L33.7891 21.8577L34.3956 21.2378H39.6484C45.3019 21.2378 50 16.5361 50 10.7583C50 4.98051 45.3019 0.278809 39.6484 0.278809Z"
    //         fill="#FED843"
    //       />
    //       <path
    //         d="M39.6484 21.2378C45.3019 21.2378 50 16.5361 50 10.7583C50 4.98051 45.3019 0.278809 39.6484 0.278809H33.7891V21.8577L34.3956 21.2378H39.6484Z"
    //         fill="#FABE2C"
    //       />
    //       <path
    //         d="M37.3047 34.7114V40.6997C37.3047 43.3046 35.2236 45.1909 32.9307 45.1909C24.4922 45.1909 16.2598 41.1139 10.1367 34.8811C4.01367 28.6483 0 20.2597 0 11.6355C0 9.15838 1.97168 7.16528 4.39453 7.16528H10.2539C10.8848 7.16528 11.4443 7.57748 11.6426 8.18828L14.5723 17.1497C14.7158 17.5889 14.6523 18.0699 14.4023 18.4542L11.918 22.2637C12.8594 24.2818 14.5254 26.4875 16.4492 28.4297C18.374 30.3709 20.5566 32.0486 22.5312 33.0107L26.2588 30.4717C26.6367 30.2172 27.1055 30.1503 27.5352 30.298L36.3242 33.2922C36.9219 33.4948 37.3047 34.0667 37.3047 34.7114Z"
    //         fill="#FF641A"
    //       />
    //       <path
    //         d="M37.3047 34.7114V40.6997C37.3047 43.3046 35.2236 45.1909 32.9307 45.1909C24.4922 45.1909 16.2598 41.1139 10.1367 34.8811L16.4492 28.4297C18.374 30.3709 20.5566 32.0486 22.5312 33.0107L26.2588 30.4717C26.6367 30.2172 27.1055 30.1503 27.5352 30.298L36.3242 33.2922C36.9219 33.4948 37.3047 34.0667 37.3047 34.7114Z"
    //         fill="#F03800"
    //       />
    //       <path
    //         d="M27.9296 12.2553C28.7386 12.2553 29.3944 11.585 29.3944 10.7582C29.3944 9.93137 28.7386 9.26111 27.9296 9.26111C27.1206 9.26111 26.4648 9.93137 26.4648 10.7582C26.4648 11.585 27.1206 12.2553 27.9296 12.2553Z"
    //         fill="#613D5C"
    //       />
    //       <path
    //         d="M33.7891 12.2551C34.5981 12.2551 35.2539 11.5849 35.2539 10.7581C35.2539 9.93125 34.5981 9.26099 33.7891 9.26099C32.9801 9.26099 32.3242 9.93125 32.3242 10.7581C32.3242 11.5849 32.9801 12.2551 33.7891 12.2551Z"
    //         fill="#613D5C"
    //       />
    //       <path
    //         d="M35.2539 10.7581C35.2539 9.93128 34.598 9.26099 33.7891 9.26099V12.2551C34.598 12.2551 35.2539 11.5848 35.2539 10.7581Z"
    //         fill="#4B2746"
    //       />
    //       <path
    //         d="M39.6483 12.2553C40.4574 12.2553 41.1132 11.585 41.1132 10.7582C41.1132 9.93137 40.4574 9.26111 39.6483 9.26111C38.8393 9.26111 38.1835 9.93137 38.1835 10.7582C38.1835 11.585 38.8393 12.2553 39.6483 12.2553Z"
    //         fill="#4B2746"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   heading: "24*7 Access to Co-mentor’s Guidance ",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="50"
    //       height="50"
    //       viewBox="0 0 50 50"
    //       fill="none"
    //     >
    //       <g clip-path="url(#clip0_158_10175)">
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M18.9807 21.6754H19.4493C22.9643 21.6754 28.676 24.751 28.9397 28.1781L28.969 37.024C28.969 37.2876 28.7346 37.5219 28.4417 37.5219H1.11307C0.820156 37.5219 0.585827 37.2876 0.615118 37.024L0.644409 28.1781C0.644409 24.3702 6.79555 21.6754 10.1055 21.6754H10.6034L14.792 26.5377L18.9807 21.6754Z"
    //           fill="#E6E7E8"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M17.3989 37.522H12.5073L13.3275 31.488C13.4446 30.6093 14.5284 31.488 15.8758 30.9901C16.2273 30.8729 16.5202 31.078 16.5788 31.4002L17.3989 37.522Z"
    //           fill="#6C6A73"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M6.47334 37.5219H5.44815V34.71H0.615112V33.7141H5.44815V32.1031C5.44815 31.4586 6.47334 31.4586 6.47334 32.1031V37.5219Z"
    //           fill="#D1D3D4"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M28.9397 33.7141L28.9689 34.71H24.1066V37.5219H23.1107V32.1031C23.1107 31.4586 24.1066 31.4586 24.1066 32.1031V33.7141H28.9397Z"
    //           fill="#D1D3D4"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M15.29 32.1617H14.3527C12.4487 32.1617 11.4236 29.7012 12.5073 29.5841V27.5923H18.0141L17.3111 29.6427C18.1312 30.0235 17.0475 32.1617 15.29 32.1617Z"
    //           fill="#787680"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M18.9807 21.6754C20.5624 21.7047 21.6169 21.8512 23.4036 22.5834L18.6878 31.8687C18.5413 32.1324 18.1019 32.1909 17.8676 31.898L14.4113 27.5629C14.2355 27.3286 14.2941 27.0064 14.4991 26.8307L18.9807 21.6754Z"
    //           fill="#D1D3D4"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M6.12186 22.5541C7.9672 21.8512 9.05097 21.7047 10.6034 21.6754L15.0557 26.8599C15.29 27.0064 15.3193 27.3286 15.1435 27.5629L11.6872 31.898C11.4528 32.1909 11.0135 32.1324 10.867 31.8687L6.12186 22.5541Z"
    //           fill="#D1D3D4"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M11.5407 18.512C11.3357 18.1019 10.6034 18.1605 10.6034 18.7463V21.6755L14.792 26.5378L18.9807 21.6755V18.7463C18.9807 18.1312 18.1312 18.0727 17.9848 18.5706L11.5407 18.512Z"
    //           fill="#FF9E7C"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M14.792 20.416C10.5448 20.416 7.08847 16.4909 7.08847 11.6872V7.3814C7.08847 6.82487 7.85004 6.737 8.05508 7.17636L16.8424 5.15527L21.5876 7.29353C21.8805 7.00062 22.4663 7.14707 22.4663 7.64502V11.6872C22.4663 16.4909 19.01 20.416 14.792 20.416Z"
    //           fill="#FFAF8A"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M21.734 9.87113C19.7715 9.87113 17.809 7.14705 17.0182 5.94611C12.273 9.959 9.63679 5.41887 8.05507 9.2853C7.85003 9.78325 7.08846 9.6368 7.08846 9.08026C7.08846 8.31869 7.05917 7.82074 7.08846 7.38138C7.17633 5.47745 7.2642 6.3269 5.47744 4.21793C5.33099 4.04218 5.3017 3.77856 5.47744 3.57353C7.05917 1.64031 10.2812 0.146461 13.1517 0.0292964C15.2607 -0.0585771 17.0475 0.585828 18.3363 1.84535C19.215 1.93322 22.4663 2.63621 22.4663 7.02988V9.34388C22.4663 9.78325 22.0855 9.87113 21.734 9.87113Z"
    //           fill="#333333"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M12.8588 11.9509C12.7709 11.9509 11.775 11.6287 11.2185 11.8923C10.9549 12.0094 10.662 11.9216 10.5448 11.6579C9.98828 10.457 13.884 10.4863 13.3275 11.6579C13.2396 11.8337 13.0639 11.9509 12.8588 11.9509Z"
    //           fill="#FF8E70"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M18.5706 11.9509C18.4827 11.9509 17.4868 11.6287 16.901 11.8923C16.3152 12.1852 15.8758 11.2479 16.4909 10.9843C17.1939 10.6621 19.4786 10.6621 19.0392 11.658C18.9514 11.8337 18.7463 11.9509 18.5706 11.9509Z"
    //           fill="#FF8E70"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M14.792 16.8717C13.4739 16.8717 11.7457 15.9344 12.4194 15.2314C12.6245 15.0264 12.9467 15.0264 13.1517 15.2314C14.0012 16.0516 15.5536 16.0516 16.4323 15.2314C16.901 14.7628 17.604 15.495 17.1353 15.9637C16.4909 16.5495 15.6415 16.8717 14.792 16.8717Z"
    //           fill="#FF8E70"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M30.5507 16.4909C30.2285 16.4909 29.9648 16.2273 29.9648 15.9051V13.0638C28.7932 12.9467 27.8852 11.9508 27.8852 10.7498V5.38956C27.8852 4.10075 28.9104 3.07556 30.1699 3.07556H38.3714C39.6309 3.07556 40.6854 4.10075 40.6854 5.38956V10.7498C40.6854 12.0387 39.6602 13.0638 38.3714 13.0638H33.597L31.0193 16.2859C30.9022 16.403 30.7264 16.4909 30.5507 16.4909Z"
    //           fill="#00CB93"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M37.4634 7.00063H31.1072C30.3164 7.00063 30.3164 5.82898 31.1072 5.82898H37.4634C38.225 5.82898 38.225 7.00063 37.4634 7.00063Z"
    //           fill="#00B784"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M37.4634 10.3104H31.1072C30.3164 10.3104 30.3164 9.13879 31.1072 9.13879H37.4634C38.225 9.13879 38.225 10.3104 37.4634 10.3104Z"
    //           fill="#00B784"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M48.8284 50H27.6509C27.3287 50 27.065 49.7364 27.065 49.4141L27.0943 42.4721C27.5044 39.543 31.9567 37.3462 34.6222 37.3462H41.8864C44.5226 37.3462 48.9748 39.543 49.3849 42.4721L49.4142 49.4141C49.4142 49.7364 49.1506 50 48.8284 50Z"
    //           fill="#4682BF"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M45.8699 50H44.6983V45.0791C44.6983 44.2882 45.8699 44.2882 45.8699 45.0791V50ZM31.7809 50H30.6093V45.0791C30.6093 44.2882 31.7809 44.2882 31.7809 45.0791V50Z"
    //           fill="#39699B"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M34.7979 37.3462V35.4423C34.7979 34.8272 35.5888 34.71 35.8817 35.1201L40.539 35.208C40.7733 34.6807 41.6813 34.7686 41.6813 35.4423V37.3462C43.0287 37.3462 44.464 37.6977 45.9871 38.5179C45.2841 40.6561 41.5642 41.3005 38.225 41.3005C33.7141 41.3005 31.2537 40.041 30.7557 39.1037C30.6678 38.9572 30.5507 38.7522 30.4921 38.5179C31.9859 37.6977 33.4505 37.3462 34.7979 37.3462Z"
    //           fill="#FF9E7C"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M39.3087 36.4967H37.1705C34.7979 36.4967 32.8647 34.5635 32.8647 32.1909V27.8851C32.8647 25.5126 34.7979 23.5793 37.1705 23.5793H39.3087C41.6813 23.5793 43.6145 25.5126 43.6145 27.8851V32.1909C43.6145 34.5635 41.6813 36.4967 39.3087 36.4967Z"
    //           fill="#FFAF8A"
    //         />
    //         <path
    //           fill-rule="evenodd"
    //           clip-rule="evenodd"
    //           d="M37.1705 23.5793C34.8272 23.5793 32.8647 25.454 32.8647 27.768V28.4417L36.3503 27.7973L43.6145 28.8518V27.8851C43.6145 25.5126 41.6813 23.5793 39.3087 23.5793H37.1705Z"
    //           fill="#333333"
    //         />
    //       </g>
    //       <defs>
    //         <clipPath id="clip0_158_10175">
    //           <rect width="50" height="50" fill="white" />
    //         </clipPath>
    //       </defs>
    //     </svg>
    //   ),
    // },
  ];

  return (
    <>
      <div className="page3">
        <div className="program">
          <div className="Heading">
            <div className="rightText"> MENTOR’s DESK </div>
          </div>

          <div className="youtubeVideo">
            <iframe
            style={{width:window.innerWidth>800?"98.9vw":"100vw",height: window.innerWidth>800 ?"70vh":"40vh"}}
              src="https://www.youtube.com/embed/mfpaUQzezlg?si=XCeP5xwSb0moKen9"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div className="videoBelowPara">
            <p>In this Program You’ll get</p>
          </div>

          <div className="programList">
            {program?.map((elem, index) => {
              return (
                <div className="listContainer">
                  <div className="listImg">
                    {elem.icon}
                  </div>

                  <div className="listContent">
                    <span>{elem.heading}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

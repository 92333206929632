import React from 'react'
import"../styles/page7.scss"



function OurTrainerPost({ post }) {
    return (
        <div className="post" style={{ cursor: "pointer" }}>


            <div className="detailed">

                <div className='content'>

                
                       <span>
                       {post?.content}
                       </span>
             

               

                </div>

                <div className='name'>

                    <span>
                    {post?.name}
                    </span>

                    {/* <text>
                    {post?.type}
                    </text> */}

                </div>

                <div>

                </div>
    



            </div>

            
        </div>
    )
}

export default OurTrainerPost
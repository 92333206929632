import React from 'react';
import "./PrivacyPolicy.scss"
function PrivacyPolicy() {
  return (
    <div className='mainPrivacy'>
    <div className="entry-content">

        <h2 className='privacyHeading'>Privacy Policy</h2>

        <p>This Privacy Policy shall form a part of the user agreement between you and Aspire Now Global.</p>

        <p>This document is an electronic record in terms of the Information Technology Act, 2000 and the rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>

        <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the privacy policy for access or usage of <a href="https://aspirenowglobal.com/" target="_blank">https://aspirenowglobal.com/</a>.</p>

        <p>The domain name <a href="https://aspirenowglobal.com/" target="_blank">https://aspirenowglobal.com/</a> (“Website”), is owned and operated by Aspire Now Global (“a private limited company, headed by its Director, Mr. Yash Gupta”) having its registered office at 8th Floor, A - Tower, Picasso Center, Sector61, Golf Course Extension Road, Gurgaon where such expression shall unless repugnant to the context thereof, be deemed to include its respective representatives, administrators, employees, directors, officers, agents, and their successors and assigns.</p>

        <p>By accessing and/or using the website, you signify your agreement to accept this privacy policy. If you do not agree with any or all the following terms of the privacy policy, please do not access and/or use the website. Providing information to us is your choice. You understand that any data, information, content or information that you submit to the website will be stored on Aspire Now Global’s servers for the purpose of making the website available to you. Please do not submit to the website any data, information, content or information which would include any personal information and sensitive personal data or information that you do not wish to be made available to Aspire Now Global.</p>

        <p>If you do not agree with any or all the terms of this privacy policy, please do not provide any information to us. If at any time you wish to discontinue your access to the website, you are free to do so. Further, if you require you can choose to have the personal information provided by you deleted by writing to <a href="mailto:admissions@aspirenowglobal.com" target="_blank">admissions@aspirenowglobal.com</a>
</p>

        <p>We reserve the right, at our sole discretion, to change or modify this privacy policy at any time without prior notice. Such changes and/or modifications shall become effective immediately upon being posted/published on the website herein.</p>

        <p>Please review the privacy policy from time-to-time. Your continued use of the website following the posting of changes and/or modifications will constitute your acceptance of any revised privacy policy. Aspire Now Global retains the right at any time to deny or suspend access to all or part of the website to anyone who Aspire Now Global believes has violated any condition of this privacy policy. </p>

        <p>If you are found reproducing our course content, or selling our strategy by recording or sharing, you must be liable for a lawsuit and a compensation of 1.5 Lakh. </p>

        {/* <h1>Collection of personal information</h1>
        <p>In any area of our website where CorporatEdge Serviced Offices Pvt Ltd asks you to provide personal information, that will enable us to enhance your site visit or to follow up with you after your visit.</p>
        <p>It is completely optional for you to participate and to give this information. For example, we request information from you when you:</p>
        <ul>
            <li>Request information about a product or service</li>
            <li>Sign up for a virtual office</li>
            <li>Arrange to visit a location</li>
            <li>Sign-up for email updates and marketing information</li>
            <li>Provide feedback in an online form</li>
        </ul>
        <p>In each of the instances above, CorporatEdge Serviced Offices Pvt Ltd may ask for your name, e-mail address, phone number, address and type of business, as well as other similar information that is needed to register or subscribe you to services or offers. If we ever ask for significantly different information we will inform you. CorporatEdge Serviced Offices Pvt Ltd may contact you with promotional offers or newsletters in the future. Contact CorporatEdge Serviced Offices Pvt Ltd at any time to unsubscribe and have your contact details removed.</p>
        <h1>Sharing of your personal information</h1>
        <p>We may occasionally hire other companies to provide services on our behalf. CorporatEdge Serviced Offices Pvt Ltd may enhance or merge your information collected at its site with data from third parties for purposes of marketing products or services to you. Those companies will be permitted to obtain only the personal information they need to deliver the service. CorporatEdge Serviced Offices Pvt Ltd takes the reasonable steps to ensure a confidentiality agreement and privacy obligations in relation to the protection of your personal information bind these organisations.</p>
        <h1>Use of Personal Information</h1>
        <p>We collect non-personally identifiable information, including but not limited to browser type, version, language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is solely used internally for the purposes of gauging visitor traffic and trends. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.</p>
        <p>The personal information you provide will be kept confidential unless otherwise stated, and used to support your customer relationship with CorporatEdge Serviced Offices Pvt Ltd. Among other things, we want to help you quickly find information on this site and alert you to product upgrades, new documentation, updated web information and other new products, services and special offers from CorporatEdge Serviced Offices Pvt Ltd.</p>
        <h1>Changes to this Privacy Policy</h1>
        <p>CorporatEdge Serviced Offices Pvt Ltd reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.</p>
        <h1>Accessing your Personal Information</h1>
        <p>You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons.</p>
        <h1>Who does this Privacy Policy apply to?</h1>
        <ul>
            <li>prospective employees</li>
            <li>prospective clients</li><li>clients</li><li>individuals who are customers of CorporatEdge Serviced Offices Pvt Ltd clients</li><li>business partners</li><li>suppliers</li><li>contractors</li><li>shareholders</li><li>other people who may come into contact with CorporatEdge Serviced Offices Pvt. Ltd through its network</li></ul>
        <ul><li>CorporatEdge Serviced Offices Pvt Ltd shall not be responsible in any manner whatsoever for any violation or misuse of your personal information by unauthorised persons consequent to misuse of the internet environment.</li></ul>
        <h1>Contacting us</h1>
        <p>CorporatEdge Serviced Offices Pvt Ltd welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by any of the following means during business hours Monday to Friday.</p>
        <p>Call: +91 124 6267878</p>
        <p>Post: Attn: Privacy Policy</p>
        <p>CorporatEdge Serviced Offices Pvt Ltd</p>
        <p>K-124, Park Place, Golf Course Road, DLF 5 Gurgaon -122002, Haryana</p> */}
    </div>
</div>
  );
}

export default PrivacyPolicy;